<template>
  <div class="modal-mask" v-if="productsId">
    <div class="modal-content-container">
      <div class="add-seller__main-container">
        <div class="new-product__header-container">
          <span class="new-product__header-title">
            Editar Múltiplos Produtos
          </span>
          <span @click="closeDialog" class="material-icons closebtn-icon">
            cancel
          </span>
        </div>
        <div class="products-page__inputs-container">
          <div class="products-page__inputs-wrapper">
            <div class="products-page__inputs-title-wrapper">
              <span class="products-page__inputs-title">Gtin</span>
            </div>
            <input v-model="newGtinValue" class="products-page__inputs" placeholder="Digite o Gtin" type="number">
          </div>
          <div class="products-page__inputs-wrapper">
            <div class="products-page__inputs-title-wrapper">
              <span class="products-page__inputs-title">Produto Padrão</span>
            </div>
            <input disabled v-model="newProdutoPadraoValue" class="products-page__inputs" placeholder="Código Produto Padrão" type="number">
          </div>
        </div>
        <div class="modal-content-body">
		    	<div class="modal-content-search-inputs">
            <StandardInputAuto :placeholder="'Digite o produto'"
                               :title="'Buscar produtos'"
                               :action="setProductValue"
                               class="products-page__selects"
                               :type="'text'"/>
			    </div>
		    	<cc-loader v-if="loading" style="max-height: fit-content;"></cc-loader>
		    	<div v-else class="modal-content-table-rows">
			    	<div class="modal-content-table-header">
				    	<div class="modal-content-table-suplier">Produto</div>
				    	<div class="modal-content-table-seller">Embalagem</div>
				    	<div class="modal-content-table-status">Código Ean</div>
				    	<div class="modal-content-table-action">Ação</div>
			    	</div>
				    <div class="modal-content-table-row" v-for="(product, idx) in products" 
                                                 :class="getSpecialBackground(idx)" 
                                                 :key="idx">
				    	<div class="modal-content-table-suplier table-suplier-text">{{ product.name }}</div>
				    	<div class="modal-content-table-seller table-seller-text">{{  product.purchase_unit  }}</div>
				    	<div class="modal-content-table-status">{{ product.ean }}</div>
					    <div class="modal-content-table-action">
                <span v-if="product.isChecked"
                      @click="removeProductCodes(product)"
                      class="material-icons-outlined checkbox-icon" 
                      style="color: #ff7110;">
                  check_box
                </span>
                <span v-else
                      class="material-icons-outlined checkbox-icon" 
                      style="color: #ff7110;"
                      @click="storeProductCodes(product)">
                  check_box_outline_blank
                </span>
              </div>
			    	</div>
			    </div>
		  	  <!-- <Pagination :key="'pag'"
			       	        classes="orange"
				              :items_by_page_value="50"
			       	        :total="total_items"
			       	        :page="page"
				              :page_limit="11"
				              v-on:change="page = $event">
          </Pagination> -->
	    	</div>
        <div class="action-btns__wrapper">
          <div style="margin-left: 1em;">
            <StandardButton :text="'Salvar'" :action="save"/>
          </div>
          <div>
            <StandardButton :text="'Cancelar'" :action="closeDialog"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as CONSTANTS from '../constants/constants'
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import Pagination from "@/components/cliente/base-components/Pagination";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputAuto from '../components/ui/inputs/StandardInputAuto.vue';

export default {
  components: {
    StandardButton,
    Pagination,
    StandardInput,
    StandardInputAuto,
  },
  props: {
    closeDialog: {
      type: Function,
    },
    productsId: {
      type: Array,
    },
  },
  emits: ['reload'],
  data() {
    return {
      newGtinValue: "",
      newProdutoPadraoValue: "",
      loading: false,
      products: [],
      total_items : 0,
      page: 1,
      filters: {
        name: "",
      },
    };
  },
  methods: {
    storeProductCodes(product) {
      this.products.forEach(product => product.isChecked = false)

      if(product.isChecked == undefined || product.isChecked == false) {
        product.isChecked = true
        this.newProdutoPadraoValue = product.id
      }
      product.isChecked = true
      this.$forceUpdate()
    },
    removeProductCodes(product) {
      product.isChecked = false
      this.$forceUpdate()
    },
    setProductValue(value){
		  this.filters.name = value
      setTimeout(() => {
        this.getProductsList(this.page)
      }, 1000);
		},
    getProductsList(page = this.page) {
      this.loading = true
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          name: this.filters.name,
          page: page,
        }
      };
      axios
        .get(`${CONSTANTS.API_V3_URL}/admin/management/products?city=&fu=&start_at=&page=1&filter=1`, config)
        .then(data => {
          this.products = data.data.data
          this.total_items = data.data.length
          this.loading = false
        });
    },
    save() {
      this.updateGtinCode()
      this.updateProdutoPadraoCode()
    },
    updateGtinCode() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
      const params = {
        id: this.productsId.toString(),
        gtin: this.newGtinValue.toString() == '' ? null : this.newGtinValue.toString(),
      }
      axios
        .put(`${CONSTANTS.API_V3_URL}/admin/management/products/product/`, params, config)
        .then(data => {
          this.$swal.fire('Configurações Salvas')
          this.closeDialog()
          this.$emit('reload')
        });
    },
    updateProdutoPadraoCode() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
      const params = {
        id: this.productsId.toString(),
        pro_padrao_id: this.newProdutoPadraoValue.toString() == '' ? null : this.newProdutoPadraoValue.toString(),
      }
      axios
        .put(`${CONSTANTS.API_V3_URL}/admin/management/products/product/`, params, config)
        .then(data => {
          this.$swal.fire('Configurações Salvas')
          this.closeDialog()
          this.$emit('reload')
        });
    },
    getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
  },
  mounted() {
    this.getProductsList()
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container{
  background-color: white;
  min-width: 50vw;
  min-height: 108vh;
  border-radius: 12px;
}
.add-seller__main-container {
  
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: #ff7110;
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.products-page__inputs-container {
  display: flex;
  margin: 1em;
}
.products-page__inputs-wrapper {
  margin: 0em 0.8em;
}
.products-page__inputs-title {
  font-size: 1.7em;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.products-page__inputs {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 0.5em;
  width: 20vw;
  height: 6vh;
  margin-top: 0.5em;
  font-size: 1.2em;
}
.products-page__inputs:focus {
  border: 1px solid #ff7110 !important;
}
.action-btns__wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin: 2em;
}
.modal-content-body {
	background-color: white;
	width: 80vw;
	/* min-height: 100vh; */
	border-radius: 0 0 10px 10px;
	padding: 2vh 2vw;
}
.modal-content-search-inputs {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2vh;
}
.modal-content-search-input {
	width: 50%;
	background: #FFFFFF;
	border: 0.5px solid #C1C1C1;
	border-radius: 8px;
	padding: 1vh 1vw;
	font-weight: 400;
	font-size: 1.4em;
	color: #808080;
	&:focus{
		outline: none;
	}
}
.modal-content-table-rows {
	overflow-y: auto;
	height: 50vh;
	margin-top: 1vh;
}
.modal-content-table-row {
	display: flex;
	align-items: center;
	padding: 1.2vh 1.5vw;
}
.modal-content-table-header {
	display: flex;
	background: #FFFEFC;
	border: 0.5px solid #E5E5E5;
	padding: 1vh 1vw;
	margin-bottom: 2vh;
	font-weight: 600;
	font-size: 1.19em;
	color: #505050;
}
.modal-content-table-suplier {flex: 1;}
.modal-content-table-seller {width: 28%;}
.modal-content-table-status {width: 15%;}
.modal-content-table-action {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1vw;
	width: 18%;
}
.table-suplier-text {
	font-weight: 600;
	font-size: 1em;
	color: #605F5F;
	text-overflow: ellipsis;
	overflow: hidden;
}
.table-seller-text {
	font-weight: 400;
	font-size: 1em;
	color: #605F5F;
	overflow: hidden;
	text-overflow: ellipsis;
}
.page-table-line-special {
	background: #F7F7F7;
}
.edit-icon {
  cursor: pointer;
}
.checkbox-icon {
  cursor: pointer;
}
.form-check-input {
  accent-color: #cc6119;
}

@media screen and (max-width: 940px) {
  .add-seller__main-container {
    height: 40vh;
  }
}
@media screen and (max-width: 640px) {
  .new-product__header-title {
    font-size: 24px;
  }
  .add-seller__main-container {
    height: 35vh;
  }
}
@media screen and (max-width: 490px) {
  .add-seller__main-container {
    width: 90vw;
    height: 35vh;
  }
  .new-product__header-title {
    font-size: 24px;
  }
  .products-page__inputs-title {
    font-size: 20px;
  }
  .products-page__inputs {
    font-size: 14px;
  }
}
</style>